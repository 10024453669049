/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700;800&display=swap');
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css2?family=Mina:wght@400;700&display=swap');


@import url(assets/css/swip.scss);
@import url(assets/css/swip-black.scss);
@font-face {
  font-family: copperplate;

  src:  /* IE6-IE8 */
   /* Modern Browsers */

       url('./assets/fonts/copperplate/Copperplate.ttf')  format('truetype') ; /* Legacy iOS */
}

@font-face {
  font-family: copperplateBold;

  src:  /* IE6-IE8 */
   /* Modern Browsers */
   url('./assets/fonts/copperplate/CopperplateBold.ttf')  format('truetype')  ; /* Legacy iOS */
   font-weight: bold;
}

@font-face {
  font-family: copperplateBoldCD;

  src:  /* IE6-IE8 */
   /* Modern Browsers */
   url('./assets/fonts/copperplate/Copperplate-Cd-Bold.ttf')  format('truetype')  ; /* Legacy iOS */
   font-weight: bold;
}

@font-face {
  font-family: minionProCD;

  src:  /* IE6-IE8 */
   /* Modern Browsers */
   url('./assets/fonts/mininion_pro_cond/mininion_pro_cond.otf')  ; /* Legacy iOS */
   font-weight: bold;
}

@font-face {
  font-family: minionCD;

  src:  /* IE6-IE8 */
   /* Modern Browsers */
   url('./assets/fonts/minion_condensed/minion_condensed.otf')  ; /* Legacy iOS */
   font-weight: bold;
}


.brandedText{
  font-family: 'copperplate', sans-serif;
}


.brandedTextHeadingProCD{
  font-family: 'minionProCD', sans-serif;
}


.brandedTextHeadingCD{
  font-family: 'minionCD', sans-serif;
}


// * {
//   font-family: 'Lexend', sans-serif;
// }

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
}

.main-container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  max-width: 1000px;
}

.scroll-outer {
  height: 100%;
}

.card-container {
  height: 100%;
  background: #2196F3;
}

.card-container h3 {
  padding: 25px;
}

.scroll-container {
  width: 25px;
  top: 25%;
  right: 12%;
  opacity: 0.7;
  position: fixed;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.scroll-container:hover {
  opacity: 1;
}

.step-section {
  padding: 8px;
  display: flex;
  flex-direction: row;
  background-color: cream;
  justify-content: flex-start;
}

.vertical-nav {
  position: relative;
}

.step-section .circle {
  background-color: white;
  border: 4px solid red;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.step-section.completed .circle {
  visibility: visible;
  border-color: rgb(0, 0, 0);
}

.step-section.active .circle {
  visibility: visible;
  border-color: rgb(0, 0, 0);
}

.step-section.empty .circle {
  visibility: hidden;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}




@keyframes _backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  20% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translateY(700px) scale(0.7);
    transform: translateY(700px) scale(0.7);
    opacity: 0.7;
  }
}

.backOutDown {
  -webkit-animation-name: _backOutDown;
  animation-name: _backOutDown;
}

._animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  // animation-delay: 500ms;
}

.glass {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.319);
}

p {
  text-align: center;
  font-size: 30px;
  color: rgb(30, 70, 120);
}

.ic {
  font-size: 70px;

  color: rgb(30, 70, 120);
}

#homec-splash-screen {
  z-index: 99999999;
  background-color: rgb(255, 255, 255) !important;
  position: fixed;
  width: 100vw;
  height: 100vh;
  .c1 {
    border-radius: 100vw;
    width: 100vw;
    height: 100vw;
    top: -100%;
    left: -50%;
    position: absolute;
    // bottom: 0%;
  }


  .c2 {
    border-radius: 100vw;
    width: 100vw;
    height: 100vw;
    top: -100%;
    left: 50%;
    position: absolute;
    // transform: translate(-50%, -50%);

  }

  .c3 {
    border-radius: 100vw;
    width: 100vw;
    height: 100vw;
    // top: 30%;
    top: 60%;
    // left: 100%;
    position: absolute;
    // transform: translate(50%, -50%);

  }


}

.red-b{
  // border: #f32121 5px solid;
  transition: transform 1.0s;

  // animation: zoomOut 2s;
  animation-fill-mode: forwards;
  border-radius: 50%;
background: #f7f7f7;
box-shadow:  20px 20px 60px #d2d2d2,
             -20px -20px 60px #ffffff;
}

.green-b{
  transition: transform 1.5s;

  // border: #11ac44 8px solid;

  // animation: zoomOut 2s;
  animation-fill-mode: forwards;
  border-radius: 50%;
background: #f7f7f7;
box-shadow:  20px 20px 60px #d2d2d2,
             -20px -20px 60px #ffffff;
}

.blue-b{
  transition: transform .8s;

  // border: #2136f3 12px solid;
  // animation: zoomOut 2s;
  animation-fill-mode: forwards;

  border-radius: 50%;
background: #f7f7f7;
box-shadow:  20px 20px 60px #d2d2d2,
             -20px -20px 60px #ffffff;
}


@keyframes zoomOut {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(0);
  }
}

html, body { height: 100%; }
body { margin: 0;  }

// common animations

.floating {
  animation-name: floating;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  // margin-left: 30px;
  // margin-top: 5px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}


.spinanim{
  -webkit-animation:spin 40s linear infinite;
  -moz-animation:spin 40s linear infinite;
  animation:spin 40s linear infinite;
}


.floating2 {
  animation-name: floating2  ;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-timing-function: linear ;
  // margin-left: 30px;
  // margin-top: 5px;
}
@keyframes floating2 {
  0% {
    transform: translate(0, -600px)  ;
  }

  50% {
    transform: translate(0, 0px)   ;
  }

  100% {
    transform: translate(0, -600px)   ;
  }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
  }
}



.floating3 {
  animation-name: floating3  ;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out ;
  // margin-left: 30px;
  // margin-top: 5px;
}
@keyframes floating3 {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 25px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.delay-2 {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

.delay-2::after {
  -webkit-animation-delay: 3100ms;
  animation-delay: 3100ms;
}

.cover-right2 {
  display: block;
  position: relative;
}

.cover-right2 > * {
  opacity: 0;
  pointer-events: none;
}

.cover-right2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: white;
}

h2.cover-right2 {
  padding-bottom: 10px;
}

.cover-right-anim2 > *,
.cover-down-anim2 > * {
  -webkit-animation-name: reveal2;
  animation-name: reveal2;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 900ms;
  animation-delay: 900ms;
  pointer-events: auto;
}

.cover-right-anim2.delay-2 > * {
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

.cover-right-anim2::after {
  -webkit-animation-duration: 1800ms;
  animation-duration: 1800ms;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.cover-right2::after {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.cover-right-anim2::after {
  -webkit-animation-name: coverRight;
  animation-name: coverRight;
}

@keyframes coverRight2 {
  0% {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  48% {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  52% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
}

@keyframes reveal2 {
  100% {
    opacity: 1;
  }
}
